/* You can add global styles to this file, and also import other style files */
@import "./assets/prime-theme.css"

.fixed-top
  height: 70px
.page
  margin-top: 70px
.cursor
  cursor: pointer

:root
  --bs-blue: #0d6efd
  --bs-blue1: #77f7f0
  --bs-blue2: #59c6d4
  --bs-blue3: #4091b2
  --bs-blue4: #2a6190
  --bs-blue5: #19386d
  --bs-blue6: #0d194b
  --bs-blue7: #400529
  --bs-indigo: #6610f2
  --bs-purple: #6f42c1
  --bs-pink: #d63384
  --bs-red: #dc3545
  --bs-orange: #fd7e14
  --bs-yellow: #ffc107
  --bs-green: #2a9df4
  --bs-teal: #20c997
  --bs-cyan: #0dcaf0
  --bs-white: #fff
  --bs-gray: #6c757d
  --bs-gray-dark: #343a40
  --bs-primary: #19386d
  --bs-secondary: #6c757d
  --bs-success: #198754
  --bs-info: #4091b2
  --bs-warning: #ffc107
  --bs-danger: #dc3545
  --bs-light: #77f7f0
  --bs-light-grey: #F3F3F3
  --bs-dark: #0400529
  --bs-font-serif: "Bitter"
  --bs-font-sans-serif: "Work Sans"
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))

.blue1
  color: var(--bs-blue1)
.blue2
  color: var(--bs-blue2)
.blue3
  color: var(--bs-blue3)
.blue4
  color: var(--bs-blue4)
.blue5
  color: var(--bs-blue5)
.blue6
  color: var(--bs-blue6)
.blue7
  color: var(--bs-blue7)
.body
  font-family: var(--bs-font-serif)
.badge
  margin-right: .5rem
.card
  border: 1px solid #e0e0e0
.text-info
  color: #2a9df4 !important

.blog-grid
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-auto-rows: 1fr
  grid-gap: 1.5rem
  align-items: stretch
  @media (max-width: 1400px)
    grid-template-columns: repeat(2, 1fr)
  @media (max-width: 768px)
    grid-template-columns: repeat(1, 1fr)
// .col-lg-4, .col-lg-8
//   border: 1px solid black
